import { Controller } from "@hotwired/stimulus"
import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr'

// Connects to data-controller="pickr"
export default class extends Controller {
  static targets = [
    "backgroundColorPicker",
    "backgroundColorInput",
    "formBackground"
  ]

  static values = {
    swatches: Array,
  }

  connect() {
    // Simple example, see optional options for more configuration.
    const pickr = Pickr.create({
        el: this.backgroundColorPickerTarget,
        theme: 'nano', // or 'classic',, 'monolith' or 'nano'

        swatches: this.swatchesValue,
        default: this.backgroundColorInputTarget.value || "white",

        components: {

            // Main components
            // preview: true,
            opacity: true,
            hue: true,

            // Input / output Options
            interaction: {
                hex: true,
                rgba: true,
                hsla: true,
                hsva: true,
                cmyk: true,
                input: true,
                clear: true,
                save: true
            }
        }
    });

    pickr.on('save', (color, instance) => {
      pickr.hide()
    })

    pickr.on('clear', (color, instance) => {
      this.formBackgroundTarget.style.backgroundColor = ""
      this.backgroundColorInputTarget.value = ""
    })

    pickr.on('change', (color, source, instance) => {
      let hexColor = `#${color.toHEXA().join("")}`
      this.formBackgroundTarget.style.backgroundColor = hexColor
      this.backgroundColorInputTarget.value = hexColor
    })
  }
}
