import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs'

// Connects to data-controller="image-croppers"
export default class extends Controller {

  static targets = [
    "image",
    "croppedImage",
    "removeImageInput",
    "removeImageContainer",
    "rotateImageContainer",
    "userSelection",
    "backgroundImageInput",
    "userSelectionWidthInput",
    "userSelectionHeightInput",
    "userSelectionTopPositionInput",
    "userSelectionLeftPositionInput",
    "userSelectionRotateInput",
  ]

  static values = {
    userSelection: Object
  }

  connect() {
    this.initializeCropper()
  }

  initializeCropper() {
    const controller = this
    this.cropper = new Cropper(this.imageTarget, {
      viewMode: 2,
      moveable: false,
      autocrop: true,
      zoomable: false,
      scalable: false,
      checkCrossOrigin: false,
      checkOrientation: false,
      data: {
        x: parseInt(controller.userSelectionValue.top),
        y: parseInt(controller.userSelectionValue.left),
        width: parseInt(controller.userSelectionValue.width),
        height: parseInt(controller.userSelectionValue.height),
        rotate: parseInt(controller.userSelectionValue.rotate),
      },
      crop(event) {
        controller.croppedImageTarget.files = controller.backgroundImageInputTarget.files

        let detail = event.detail;

        controller.userSelectionWidthInputTarget.value = detail.width;
        controller.userSelectionHeightInputTarget.value = detail.height;
        controller.userSelectionTopPositionInputTarget.value = detail.x;
        controller.userSelectionLeftPositionInputTarget.value = detail.y;
        controller.userSelectionRotateInputTarget.value = detail.rotate || 0;
      },
    });
  }

  updateImage(event) {
    if (this.cropper) {
      this.cropper.destroy()
    }
    this.initializeCropper()

    const [file] = event.target.files;

    if (file) {
      const maxFileSizeInMB = 5;
      const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;

      var url = URL.createObjectURL(file)
      this.cropper.replace(url)
      this.removeImageInputTarget.checked = false
      this.removeImageContainerTarget.classList.remove("hidden")
      this.rotateImageContainerTarget.classList.remove("hidden")
    } else {
      this.removeImageContainerTarget.classList.add("hidden")
      this.imageTarget.src = ""
    }
  }

  rotateRight(event) {
    event.preventDefault();
    this.cropper.rotate(90);
    this.cropper.clear()
  }

  removeImage(event) {
    this.imageTarget.src = ""
    if (this.cropper) {
      this.cropper.destroy()
    }
    this.removeImageInputTarget.checked = false
    this.removeImageContainerTarget.classList.add("hidden")
    this.rotateImageContainerTarget.classList.add("hidden")
  }

}
