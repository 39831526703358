import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { getMetaValue } from "../plugins/src/metadata"

export default class extends Controller {

  connect() {
    Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      group: 'nested',
      animation: 150,
      handle: ".sortable-page-handle",
      fallbackOnBody: true,
      swapThreshold: 0.65,
    });
  }

  onEnd(event) {
    let url = event.item.dataset.pageSortablesUrl

    let parent_page_id = event.to.parentNode.dataset.id || ""
    let page_order_position = event.newIndex

    if (page_order_position === "") {
      page_order_position = ""
    }

    let data = {
          website_id: event.to.dataset.pageSortablesWebsiteId,
          page_order_position: page_order_position,
          sub_page_order_position: event.newIndex,
          parent_page_id: parent_page_id,
        }

    fetch(url, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        body: JSON.stringify(data)
    })
      .then(response => response.json())
      .catch(error => console.error(error));
  }

}
