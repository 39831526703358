import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-rows"
export default class extends Controller {
  static targets = ["element"]

  connect() {
    if (this.hasElementTarget) {
      this.elementTarget.scrollIntoView({ behavior: "smooth" })
    }
  }
}
