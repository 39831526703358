import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="newsletters"
export default class extends Controller {
  static targets = [
    "stateInput",
    "sendEmailContainer",
    "sendEmailInput"
  ]

  connect() {
    this.toggleSendEmailContainer()
    if (this.hasSendEmailInputTarget) {
      this.sendEmailInputTarget.checked = false
    }
  }

  removeCallToAction() {
    let element = document.querySelector(".call-to-action");
    if (element) {
      element.remove()
    }
  }

  toggleSendEmailContainer() {
    if (this.hasStateInputTarget) {
      if (this.stateInputTarget.value == "published") {
        this.sendEmailContainerTarget.classList.remove("hidden")
        this.sendEmailInputTarget.checked = true
      } else {
        this.sendEmailContainerTarget.classList.add("hidden")
        this.sendEmailInputTarget.checked = false
      }
    }
  }
}
