import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "../plugins/src/metadata"

// Connects to data-controller="row-settings"
export default class extends Controller {
  static targets = ["height", "row", "form"]

  connect() {
    this.startY = 0
    this.startHeight = 0
    this.pointerMoveHandler = this.pointerMove.bind(this)
    this.pointerUpHandler = this.pointerUp.bind(this)
    this.heightTarget.value = this.rowTarget.dataset.height || this.element.clientHeight
  }

  startDrag(event) {
    event.preventDefault()
    this.startY = event.clientY
    this.startHeight = parseInt(document.defaultView.getComputedStyle(this.rowTarget).height, 10)
    document.documentElement.addEventListener('pointermove', this.pointerMoveHandler)
    document.documentElement.addEventListener('pointerup', this.pointerUpHandler)
  }

  pointerMove(event) {
    const deltaY = event.clientY - this.startY
    let newHeight = parseInt(((this.startHeight + deltaY) / window.innerHeight) * 100)
    this.rowTarget.style.height = `${newHeight}vh`
    this.heightTarget.value = newHeight
  }

  pointerUp() {
    document.documentElement.removeEventListener('pointermove', this.pointerMoveHandler)
    document.documentElement.removeEventListener('pointerup', this.pointerUpHandler)
    this.formTarget.requestSubmit()
  }
}
