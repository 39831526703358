import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.closeNotification()
    }, 8000);
  }

  closeNotification() {
    this.element.classList.add("transition")
    this.element.classList.add("ease-in")
    this.element.classList.add("duration-5000")
    this.element.classList.remove("opacity-100")
    this.element.classList.add("opacity-0")
    this.element.remove()
  }
}
