import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="classrooms-roles"
export default class extends Controller {
  static targets = ["role", "classrooms"]

  connect() {
    this.roleTargets.forEach(element => {
      if ((element.dataset.role === 'Journal admin' && element.checked) || (element.dataset.role === 'Account admin' && element.checked)) {
        this.classroomsTarget.classList.remove('hidden')
      }
    })
  }

  toggleClassrooms(event) {
    let classroomRoles = this.roleTargets.filter(element => element.dataset.role === 'Journal admin' || element.dataset.role === 'Account admin')

    if (classroomRoles.some(element => element.checked)) {
      this.classroomsTarget.classList.remove('hidden')
    } else {
      this.classroomsTarget.classList.add('hidden')
    }
  }
}
