import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drag-drop"
export default class extends Controller {
  static targets = ['dropArea', 'imagePreview'];

  connect() {
    this.dropAreaTarget.addEventListener('dragover', this.handleDragOver.bind(this));
    this.dropAreaTarget.addEventListener('dragenter', this.handleDragEnter.bind(this));
    this.dropAreaTarget.addEventListener('dragleave', this.handleDragLeave.bind(this));
    this.dropAreaTarget.addEventListener('drop', this.handleDrop.bind(this));
  }

  handleDragOver(event) {
    event.preventDefault();
  }

  handleDragEnter(event) {
    event.target.classList.add('drag-over');
  }

  handleDragLeave(event) {
    event.target.classList.remove('drag-over');
  }

  handleDrop(event) {
    event.preventDefault();
    event.target.classList.remove('drag-over');

    const files = event.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = () => {
        this.imagePreviewTarget.src = reader.result;
      };

      reader.readAsDataURL(file);
    }
  }
}

