import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigable-sub-pages"
export default class extends Controller {
  static targets = [ "navigableChecbox" ]

  connect() {
  }

  makeNavigable(event) {
    if (event.target.value.length > 0) {
      this.navigableChecboxTarget.checked = true
    }
  }
}
