import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dialogElement"
  ]

  connect() {
    this.element.addEventListener("turbo:submit-end", event => this.handleSubmissionResponse(event))
    this.dialogElementTarget.addEventListener("close", event => this.clearDialog(event))
  }

  openDialog() {
    this.dialogElementTarget.showModal()
  }

  closeDialog() {
    this.dialogElementTarget.close()
    this.clearDialog()
  }

  handleSubmissionResponse(event) {
    if (event.detail.success) {
      this.dialogElementTarget.close()
      this.clearDialog()
    }
  }

  clearDialog(_event) {
    this.dialogElementTarget.querySelectorAll("turbo-frame").forEach(element => element.innerHTML = "")
  }

}
