import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ["slide"]
  static values = {
    images: Array
  }

  connect() {
    this.currentIndex = 0
    if (this.imagesValue.length > 1) {
      this.showImage(this.currentIndex)
      this.startCarousel()
    } else {
      this.slideTarget.style.backgroundImage = `url(${this.imagesValue[0]})`
    }
  }

  startCarousel() {
    this.startTimer()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imagesValue.length
    this.showImage(this.currentIndex)
    this.resetTimer()
  }

  previous() {
    this.currentIndex = (this.currentIndex - 1 + this.imagesValue.length) % this.imagesValue.length
    this.showImage(this.currentIndex)
    this.resetTimer()
  }

  showImage(index) {
    this.slideTarget.classList.add('opacity-25')
    setTimeout(() => {
      this.slideTarget.style.backgroundImage = `url(${this.imagesValue[index]})`
      this.slideTarget.classList.remove('opacity-25')
      this.slideTarget.classList.add('opacity-100')
      setTimeout(() => {
        this.slideTarget.classList.remove('opacity-100')
      }, 750)
    }, 1000)
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.next()
    }, 5000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  resetTimer() {
    this.stopTimer()
    this.startTimer()
  }
}
