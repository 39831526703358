import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { getMetaValue } from "../plugins/src/metadata"

export default class extends Controller {

  connect() {
    Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      group: 'shared',
      animation: 150,
      handle: ".sortable-newsletter-article-handle",
    });
  }

  onEnd(event) {
    let url = event.item.dataset.newsletterArticleSortablesUrl
    let data = {
          newsletter_id: event.to.dataset.pageSortablesNewsletterId,
          newsletter_article_order_position: event.newIndex,
        }

    fetch(url, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        body: JSON.stringify(data)
    })
      .then(response => response.json())
      .catch(error => console.error(error));
  }

}
