import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "dateField" ]

  connect() {
    this.instantiateFlatpickr()
  }

  instantiateFlatpickr(event) {
    if (this.hasDateFieldTarget) {
      flatpickr(this.dateFieldTarget, {
        minDate: "today",
        dateFormat: "d/m/Y - h:i K",
        allowInput: true,
        disableMobile: true,
        enableTime: true,
      });
    }
  }

}
