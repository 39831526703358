import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="website-navbar-small"
export default class extends Controller {

  connect() {
  }

  showSubMenu(event) {
    let element = event.currentTarget
    let subMenu = document.querySelector(`[aria-labelledby="small-${element.id}"]`)
    if ((subMenu != null) && (subMenu.classList.contains("md:hidden") || subMenu.classList.contains("hidden"))) {
      subMenu.classList.remove("hidden")
      subMenu.classList.remove("md:hidden")
    } else {
      subMenu.classList.add("hidden")
      subMenu.classList.add("md:hidden")
    }
  }

  hideAnyOpenSubMenus() {
    let subMenus = document.querySelectorAll("div[role='smallScreenSubMenu']");
    subMenus.forEach((subMenu) => {
      subMenu.classList.add("hidden")
      subMenu.classList.add("md:hidden")
    })
  }

}
