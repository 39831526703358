import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "../plugins/src/metadata"

// Connects to data-controller="message"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
    // Create an instance of IntersectionObserver
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.markRead()
        }
      })
    })

    // Start observing the message element
    this.observer.observe(this.element)
  }

  disconnect() {
    // Stop observing when the element is removed from the DOM
    this.observer.unobserve(this.element)
  }

  markRead() {
    // Send a request to mark the message as read
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.ok) {
        // Optionally, update the UI to reflect the read status
        // this.element.querySelector('small').innerText = `Read at ${new Date().toLocaleString()}`
        this.observer.unobserve(this.element)
        this.element.removeAttribute('data-controller')
        this.element.removeAttribute('data-message-url-value')
      }
    })
  }
}
