import { Controller } from "@hotwired/stimulus";
import { getMetaValue } from "../plugins/src/metadata"
import {
  InlineEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BlockQuote,
  BlockToolbar,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  MediaEmbed,
  Mention,
  PageBreak,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SimpleUploadAdapter,
  // SpecialCharacters,
  // SpecialCharactersArrows,
  // SpecialCharactersCurrency,
  // SpecialCharactersEssentials,
  // SpecialCharactersLatin,
  // SpecialCharactersMathematical,
  // SpecialCharactersText,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo
} from 'ckeditor5';

const items = [
      'undo',
      'redo',
      '|',
      'selectAll',
      '|',
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'removeFormat',
      '|',
      // 'specialCharacters',
      'horizontalLine',
      'pageBreak',
      'link',
      'insertImage',
      'mediaEmbed',
      'insertTable',
      'highlight',
      'blockQuote',
      '|',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
      'indent',
      'outdent',
      '|',
      'accessibilityHelp'
    ];

const plugins = [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    BlockQuote,
    BlockToolbar,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    Highlight,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    MediaEmbed,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    SimpleUploadAdapter,
    // SpecialCharacters,
    // SpecialCharactersArrows,
    // SpecialCharactersCurrency,
    // SpecialCharactersEssentials,
    // SpecialCharactersLatin,
    // SpecialCharactersMathematical,
    // SpecialCharactersText,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo
  ];

const headingOptions = [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ];

const imageToolbar = [
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage'
        ];

const blockToolbarOptions = [
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  '|',
  'link',
  'insertImage',
  'insertTable',
  '|',
  'bulletedList',
  'numberedList',
  'indent',
  'outdent'
]

// Connects to data-controller="rich-text-editors"
export default class extends Controller {
  static targets = [
    "toolbarContainer",
    "editor",
    "statusIndicator",
    "colorIndicator",
    "colorGlowIndicator",
  ]
  static values = {
    url: String,
    imageUrl: String,
    methodType: String
  }

  connect() {
    this.initializeEditor();
  }

  initializeEditor() {
    const controller = this;
    InlineEditor.create(this.editorTarget, {
      extraPlugins: [ Autosave, SimpleUploadAdapter, MediaEmbed, Mention, MentionCustomization ],
      simpleUpload: {
        uploadUrl: controller.imageUrlValue,
        withCredentials: true,
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token"),
        }
      },
      toolbar: {
        items: items,
        shouldNotGroupWhenFull: true
      },
      plugins: plugins,
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 28, 30, 36, 42, 48, 60, 72],
        supportAllValues: true
      },
      heading: {
        options: headingOptions
      },
      image: {
        toolbar: imageToolbar,
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      autosave: {
        waitingTime: 1000,
        save( editor ) {
          controller.saveData(editor.getData());
        }
      },
      blockToolbar: blockToolbarOptions,
      mediaEmbed: {
        previewsInData: true
      },
      mention: {
        feeds: [
          {
            marker: '#',
            feed: fetchEvents,
            minimumCharacters: 0,
            dropdownLimit: 5,
          },
          {
            marker: '@',
            feed: fetchPages,
            minimumCharacters: 0,
            dropdownLimit: 5,
          },
        ]
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]
      },
      placeholder: 'Type or paste your content here!',
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      }
    })
      .then( editor => {
        this.toolbarContainerTarget.appendChild( editor.ui.view.toolbar.element );
        this.displayStatus( editor );
      })
      .catch( error => {
        console.error( error );
      });
  }

  saveData(data) {
    fetch(this.urlValue, {
      method: this.methodTypeValue,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      body: JSON.stringify({ editor: { content: data } }),
    })
    .then(response => response.json())
    .then(data => { })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  }

  // Update the "Status: Saving..." information.
  displayStatus( editor ) {
      const pendingActions = editor.plugins.get( 'PendingActions' );
      const saveButton = document.querySelector("input[type='submit']")

      pendingActions.on( 'change:hasAny', ( evt, propertyName, newValue ) => {
          if ( newValue ) {
            saveButton.disabled = true
            saveButton.value = "Saving..."
            this.statusIndicatorTarget.innerText = "Saving...";
            this.colorGlowIndicatorTarget.classList.remove("bg-emerald-500/20")
            this.colorIndicatorTarget.classList.remove("bg-emerald-500")
            this.colorGlowIndicatorTarget.classList.add("bg-amber-500/20")
            this.colorIndicatorTarget.classList.add("bg-amber-500")
          } else {
            saveButton.disabled = false
            saveButton.value = "Save"
            this.statusIndicatorTarget.innerText = "Saved";
            this.colorGlowIndicatorTarget.classList.add("bg-emerald-500/20")
            this.colorIndicatorTarget.classList.add("bg-emerald-500")
            this.colorGlowIndicatorTarget.classList.remove("bg-amber-500/20")
            this.colorIndicatorTarget.classList.remove("bg-amber-500")
          }
      } );
  }

}

function MentionCustomization( editor ) {
  // The upcast converter will convert view <a class="mention" href="" data-user-id="">
  // elements to the model 'mention' text attribute.
  editor.conversion.for( 'upcast' ).elementToAttribute( {
      view: {
          name: 'a',
          key: 'data-turbo',
          classes: '',
          attributes: {
              href: true,
              'data-turbo': true
          }
      },
      model: {
          key: 'mention',
          value: viewItem => {
              // The mention feature expects that the mention attribute value
              // in the model is a plain object with a set of additional attributes.
              // In order to create a proper object use the toMentionAttribute() helper method:
              const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                  // Add any other properties that you need.
                  link: viewItem.getAttribute( 'href' ),
                  classes: viewItem.getAttribute( 'class' ),
                  turbo: viewItem.getAttribute( 'data-turbo' ),
                  // mentionId: viewItem.getAttribute( 'data-mention-id' )
              } );

              return mentionAttribute;
          }
      },
      converterPriority: 'high'
  } );

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for( 'downcast' ).attributeToElement( {
      model: 'mention',
      view: ( modelAttributeValue, { writer } ) => {
          // Do not convert empty attributes (lack of value means no mention).
          if ( !modelAttributeValue ) {
              return;
          }

          return writer.createAttributeElement( 'a', {
              class: modelAttributeValue.classes,
              'data-turbo': false,
              // 'data-mention': modelAttributeValue.id,
              // 'data-mention-id': modelAttributeValue.mentionId,
              'href': modelAttributeValue.link
          }, {
              // Make mention attribute to be wrapped by other attribute elements.
              priority: 20,
              // Prevent merging mentions together.
              id: modelAttributeValue.uid
          } );
      },
      converterPriority: 'high'
  } );
}

function fetchEvents(query) {
  return fetch("/rich_text_editor/events?" + new URLSearchParams({query: query}), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getMetaValue("csrf-token"),
    },
  })
  .then(response => response.json())
  .catch(error => {
    console.error("Error fetching data:", error);
  });
}

function fetchPages(query) {
  return fetch("/rich_text_editor/pages?" + new URLSearchParams({query: query}), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getMetaValue("csrf-token"),
    },
  })
  .then(response => response.json())
  .catch(error => {
    console.error("Error fetching data:", error);
  });
}
