import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-forms"
export default class extends Controller {
  static targets = [
    "preferredContactMethod",
    "phoneField",
    "emailField"
  ]

  connect() {
    this.preferredContactMethodSelection()
  }

  preferredContactMethodSelection() {
    let value = this.preferredContactMethodTarget.value
    if (value == "Phone") {
      this.emailFieldTarget.classList.add("hidden")
      this.phoneFieldTarget.classList.remove("hidden")
    } else if (value == "Email") {
      this.phoneFieldTarget.classList.add("hidden")
      this.emailFieldTarget.classList.remove("hidden")
    } else {
      this.phoneFieldTarget.classList.add("hidden")
      this.emailFieldTarget.classList.add("hidden")
    }
  }

}
