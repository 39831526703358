import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropArea", "fileInput", "fileList", "dropText"];
  fileList = [];

  connect() {
    this.dropAreaTarget.addEventListener("dragenter", this.showDropText.bind(this));
    this.dropAreaTarget.addEventListener("dragleave", this.hideDropText.bind(this));
    this.dropAreaTarget.addEventListener("dragover", this.preventDragDefault.bind(this));
    this.dropAreaTarget.addEventListener("drop", this.handleDrop.bind(this));
    this.fileInputTarget.addEventListener("change", this.handleDrop.bind(this));
  }

  showDropText(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropTextTarget.innerText = "Drop file(s)";
  }

  hideDropText(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropTextTarget.innerText = "Drag & drop files here";
  }

  preventDragDefault(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropTextTarget.innerText = "Drop file(s)";
  }

  handleDrop(event) {
    const files = event.type === "drop" ? event.dataTransfer.files : event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.fileList.push(files[i]);
      }
      this.updateFileList();
      this.hideDropText(event);
    }
  }

  removeFile(event) {
    let id = event.target.dataset.documentPosition;
    this.fileList.splice(id, 1);
    this.updateFileList();
  }

  updateFileList() {
    this.fileListTarget.innerHTML = "";
    this.fileList.forEach((file, index) => {
      const listItem = document.createElement("span");
      listItem.classList.add("text-sm", "text-slate-400", "py-1", "flex", "gap-2");
      listItem.innerHTML = `
        <a class="text-xs text-gray-300 border border-gray-100 rounded py-1 px-2 hover:bg-red-600 hover:text-white group-hover/controls:bg-gray-200 group-hover/controls:text-black" href="#" data-document-position=${index} data-action="click->drop-attachments#removeFile:stop:prevent">
          remove
        </a>
        ${file.name}
      `;
      this.fileListTarget.appendChild(listItem);
    });

    const newFileList = new DataTransfer();
    this.fileList.forEach((file) => {
      newFileList.items.add(file);
    });
    this.fileInputTarget.files = newFileList.files;
  }
}

