import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="column-settings"
export default class extends Controller {
  static targets = ["height", "column", "form", "top"]

  connect() {
    this.startY = 0
    this.startHeight = 0
    this.pointerMoveHandler = this.pointerMove.bind(this)
    this.pointerUpHandler = this.pointerUp.bind(this)
    this.heightTarget.value = this.columnTarget.dataset.height
    this.columnTarget.style.height = `${this.columnTarget.dataset.height}vh` || '10vh'

    this.isResizing = false
    this.isDraggingChild = false

    this.childStartY = 0
    this.childStartTop = 0
    this.dragChildMoveHandler = this.dragChildMove.bind(this)
    this.topTarget.value = this.columnTarget.dataset.top
  }

  startDrag(event) {
    event.preventDefault()
    this.startY = event.clientY
    this.startHeight = parseInt(document.defaultView.getComputedStyle(this.columnTarget).height, 10)
    this.isResizing = true
    document.documentElement.addEventListener('pointermove', this.pointerMoveHandler)
    document.documentElement.addEventListener('pointerup', this.pointerUpHandler)
  }

  pointerMove(event) {
    if (this.isResizing) {
      const deltaY = event.clientY - this.startY
      let newHeight = parseInt(((this.startHeight + deltaY) / window.innerHeight) * 100)
      this.columnTarget.style.height = `${newHeight}vh`
      this.heightTarget.value = newHeight
    } else if (this.isDraggingChild) {
      const deltaY = event.clientY - this.childStartY
      let newTop = this.childStartTop + deltaY
      this.topTarget.value = newTop
      this.columnTarget.style.top = `${newTop}px`
    }
  }

  pointerUp() {
    this.isResizing = false
    this.isDraggingChild = false
    document.documentElement.removeEventListener('pointermove', this.pointerMoveHandler)
    document.documentElement.removeEventListener('pointerup', this.pointerUpHandler)
    this.formTarget.requestSubmit()
  }

  startDragChild(event) {
    event.preventDefault()  // Prevent text selection during drag
    this.childStartY = event.clientY
    this.childStartTop = parseInt(document.defaultView.getComputedStyle(this.columnTarget).top, 10)
    this.isDraggingChild = true
    document.documentElement.addEventListener('pointermove', this.pointerMoveHandler)
    document.documentElement.addEventListener('pointerup', this.pointerUpHandler)
  }

  dragChildMove(event) {
    const deltaY = event.clientY - this.childStartY
    let newTop = this.childStartTop + deltaY
    this.topTarget.value = newTop
    this.columnTarget.style.top = `${newTop}px`
  }
}
