import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "slideOverContainer"
  ]

  static values = {
  }

  connect() {
    document.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        this.hideSlideover(new Event("click"))
      }
    })
  }

  hideSlideover(event) {
    event.preventDefault()
    let slideOver = document.getElementById("slideOverContainer")
    slideOver.classList.add("translate-x-full", "hidden")
    slideOver.classList.remove("transform", "transition", "ease-in-out", "duration-500", "sm:duration-700")
  }

  showSlideover() {
    let slideOver = document.getElementById("slideOverContainer")
    slideOver.classList.remove("translate-x-full")
    slideOver.classList.remove("transform", "transition", "ease-in-out", "duration-500", "sm:duration-700", "hidden")
  }

}
