import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {

  static targets = [ "smallScreenNavbar" ]

  connect() {
  }

  toggleSmallScreenNavbar() {
    if (this.smallScreenNavbarTarget.classList.contains("hidden")) {
      this.openSlidebar()
    } else {
      this.closeSlideBar()
    }
  }

  closeSlideBar() {
    this.smallScreenNavbarTarget.classList.add("hidden")
  }

  openSlidebar() {
    this.smallScreenNavbarTarget.classList.remove("hidden")
  }

}
