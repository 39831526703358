import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "label", "subPages" ]

  connect() {
  }

  toggle() {
    if (this.labelTarget.innerText == "Show") {
      this.labelTarget.innerText = "Hide";
      this.subPagesTarget.classList.remove("hidden");
    } else {
      this.labelTarget.innerText = "Show";
      this.subPagesTarget.classList.add("hidden");
    }
  }

}
